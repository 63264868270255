/* src/styles.css */

body {
    background-color:#CFBEA0 ;
    margin: 0;
    font-family: Arial, sans-serif;
}

.container {
    text-align: center;
    padding-top: 50px;
}

.navbar {
    display: flex;
    justify-content: center;
    background-color: #333333;
    padding: 20px 0;
}

.navbar a {
    color: #C0C0C0;
    margin: 0 20px;
    text-decoration: none;
}

.heading {
    display:flex;
    color: #4683B4;
    justify-content: center;
    margin-bottom: 20px;
}
